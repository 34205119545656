<template>
  <v-app
    class="fill-height"
    fluid
  >
    <v-app-bar
      :value="false"
      fixed
      dense
      scroll-off-screen
      elevation="2"
    >
      <v-img
        v-if="lab_result && lab_result.client"
        :src="lab_result.client.logo"
        :contain="true"
        :height="32"
        width="40%"
        position="left"
      />
      <v-spacer />
      <v-img
        :src="$vuetify.breakpoint.mobile ? '/img/meds-matrix-logo-icon.svg' : '/img/meds-matrix-logo.svg'"
        :contain="true"
        :height="18"
        width="40%"
        position="right"
      />
    </v-app-bar>
    <v-overlay
      :value="loading"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="150"
        width="10"
      />
    </v-overlay>
    <v-container>
      <v-row
        v-if="!loading && lab_result && lab_result.external_id"
        xclass="my-12"
        class="mb-12"
        align="center"
        justify="center"
        dense
      >
        <v-col
          sm="12"
          md="10"
          lg="9"
          xl="8"
        >
          <v-row dense>
            <v-col
              cols="12"
            >
              <v-card
                color="indigo darken-3"
                dark
                flat
              >
                <v-card-title class="d-flex justify-space-between">
                  <div>
                    {{ $tc('Lab Result', 1) }} <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span>
                  </div>
                  <div>
                    <v-btn
                      small
                      light
                    >
                      <v-icon
                        color="red"
                        left
                      >
                        fal fa-file-pdf
                      </v-icon>
                      Print
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-space-between">
                  <div>
                    <template
                      v-if="lab_result.patient.uuid"
                    >
                      {{ $t(lab_result.patient.gender + ' patient') | capitalize }}, {{ patient_age }} {{ $tc('year old', patient_age) }}
                    </template>
                    <template v-else>
                      {{ $tc('patient', 1) | capitalize }} XXXX, XX {{ $tc('year old', patient_age) }}
                    </template>
                    <br>
                    <source-chip
                      :key="lab_result.sample_type"
                      :name="lab_result.sample_type"
                      :source="lab_result.sample_source"
                      :outlined="false"
                      class="mt-1"
                      color="indigo lighten-5"
                      xtext-color="white"
                      small
                      light
                    />
                  </div>
                  <v-select
                    v-model="$i18n.locale"
                    class="text-caption mb-n7 mt-2 px-0"
                    :items="[
                      {
                        value: 'en', text: 'English', menu: 'en',
                      },{
                        value: 'de', text: 'Deutsch', menu: 'de',
                      },{
                        value: 'es', text: 'Español', menu: 'es',
                      },{
                        value: 'fr', text: 'Français', menu: 'fr',
                      },{
                        value: 'pt', text: 'Português', menu: 'pt',
                      },{
                        value: 'iw', text: 'עברית', menu: 'ע',
                      }
                    ]"
                    dense
                    :append-icon="null"
                    prepend-icon="fad fa-fw fa-language"
                    :style="$vuetify.breakpoint.mobile ? 'max-width: 72px;': 'max-width: 124px;'"
                    item
                    solo-inverted
                    flat
                  >
                    <template
                      v-if="$vuetify.breakpoint.mobile"
                      #selection="{ item }"
                    >
                      {{ item.menu | uppercase }}
                    </template>
                  </v-select>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              xclass="mb-12"
            >
              <v-card>
                <v-card-title
                  class="purple darken-3 branded"
                >
                  <v-img
                    src="/img/meds-matrix-logo-white.svg"
                    :contain="true"
                    xheight="20"
                    :max-width="150"
                  />
                  <v-spacer />
                  <v-menu
                    offset-x
                    offset-y
                    :close-on-content-click="false"
                    min-width="370"
                    style="z-index:300"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        small
                        v-on="on"
                      >
                        <b>Live</b>Factor
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-switch
                          v-model="lab_result.pregnant"
                          :label="$t('live_factor.patient_pregnant')"
                          prepend-icon="fal fa-person-pregnant"
                          color="pink darken-1"
                        />
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-autocomplete
                          v-model="lab_result.allergies"
                          :label="$t('live_factor.allergies')"
                          prepend-icon="fal fa-allergies"
                          class="mt-4 mb-n2"
                          :items="allergies"
                          item-text="name"
                          return-object
                          small-chips
                          multiple
                        />
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-autocomplete
                          v-model="lab_result.resistance"
                          :label="$t('live_factor.resistance_genes')"
                          prepend-icon="fal fa-shield-virus"
                          class="mt-4 mb-n2"
                          :items="resistances"
                          return-object
                          item-text="name"
                          small-chips
                          multiple
                        />
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-autocomplete
                          v-model="lab_result.drug_resistance"
                          :label="$t('live_factor.drug_resistance')"
                          prepend-icon="fak fa-shield-pill"
                          class="mt-4 mb-n2"
                          :items="lab_result.organisms.flatMap(x => x.effective_drugs).sort((a,b) => b.name > a.name ? -1 : 1).map(x => x.name)"
                          small-chips
                          multiple
                        />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <treatment-table
                  v-if="lab_result.organisms"
                  id="treatment-matrix"
                  :key="lab_result.match_hash"
                  :source="lab_result.sample_source"
                  :organisms="detected_organisms"
                  :pathogens="detected_pathogens.map(x => x.name)"
                  :resistance="lab_result.resistance"
                  :allergies="lab_result.allergies"
                  :pregnant="lab_result.pregnant"
                  :pediatric="pediatric"
                  :drug-resistance="lab_result.drug_resistance"
                  :selected-drug="selected_drug"
                  clickable-drugs
                  width="100%"
                  @resize="matrix_height = $event"
                  @drug-click="v => selected_drug = v"
                />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="7"
            >
              <v-card>
                <v-card-title
                  v-if="selected_drug"
                  ripple
                >
                  {{ selected_drug.name }}
                </v-card-title>
                <v-card-text>
                  <drug-info
                    v-if="selected_drug"
                    :drug="selected_drug"
                    show-standard-dosages
                  />
                  <span
                    v-else
                    class="text-body-1"
                  >
                    Select a drug in the MedsMatrix to see detailed info
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="6"
              lg="5"
            >
              <v-card>
                <arkscore-card
                  :name="$t('arkscore.infection_complexity')"
                  :score="lab_result.score_infection_complexity"
                  :bar-height="10"
                  blurb="The combination of organisms and resistance detected affect the level of difficulty in treating the infection. The more complex the infection, the less treatment options are available."
                  flat
                />
                <v-card-text
                  v-if="lab_result.interpretation && lab_result.interpretation.instructions"
                  class="mt-n4"
                >
                  {{ instructions['one_choice_explained'] }}
                </v-card-text>
              </v-card>

              <v-expansion-panels
                focusable
                accordion
                dense
                class="mt-6 mb-2"
              >
                <v-expansion-panel>
                  <v-card-title
                    class="text-overline"
                  >
                    <v-icon
                      class="mr-1"
                      small
                    >
                      fal fa-fw fa-viruses
                    </v-icon>
                    {{ $t('Organisms Detected') }}
                  </v-card-title>
                  <v-card-subtitle
                    v-if="detected_pathogens.length != detected_organisms.length"
                    class="font-italic"
                  >
                    {{ $t('organisms_detected.subtitle', { source: $t(lab_result.sample_source) }) }}
                  </v-card-subtitle>
                </v-expansion-panel>
                <v-expansion-panel
                  v-for="(organism, index) in orderBy(lab_result.organisms.filter(x => x.result.detected), 'name')"
                  :key="index"
                >
                  <v-expansion-panel-header
                    :class="{ 'font-weight-bold': organism.significant }"
                  >
                    {{ organism.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mt-4">
                      {{ organism.description[$i18n.locale] }}
                    </p>
                    <template v-if="organism.infections.length">
                      <div class="text-overline mt-3">
                        {{ $t('Commonly Associated Infections') }}
                      </div>
                      <ul>
                        <li
                          v-for="(infection, id) in organism.infections"
                          :key="id"
                        >
                          {{ infection.name }}
                        </li>
                      </ul>
                    </template>
                    <!-- <template v-if="organism.effective_drugs">
                      <div class="text-overline mt-3">
                        {{ $t('Drugs with Known Activity') }}
                      </div>
                      <ul class="ml-n2">
                        <li
                          v-for="(drug, id) in organism.effective_drugs"
                          :key="id"
                        >
                          {{ drug.name.en }}
                        </li>
                      </ul>
                    </template> -->
                    <template v-if="organism.references && organism.references.length">
                      <div class="text-overline mt-3">
                        {{ $t('References') }}
                      </div>
                      <reference-links
                        :references="organism.references"
                        searchable
                      />
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels
                v-if="resistance.length"
                focusable
                accordion
                class="mt-6 mb-2"
              >
                <v-expansion-panel>
                  <v-card-title
                    class="text-overline"
                  >
                    <v-icon
                      class="mr-1"
                      small
                    >
                      fal fa-fw fa-shield-virus
                    </v-icon>
                    {{ $t('Detected Antibiotic Resistance') }}
                  </v-card-title>
                </v-expansion-panel>
                <v-expansion-panel
                  v-for="(resistance, index) in orderBy(resistance, 'name')"
                  :key="index"
                >
                  <v-expansion-panel-header ripple>
                    {{ resistance.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mt-4">
                      {{ resistance.description }}
                    </p>
                    <template v-if="resistance.drugs.length">
                      <div class="text-overline mt-3">
                        {{ $t('Drugs in this Category') }}
                      </div>
                      <ul>
                        <li
                          v-for="(drug, id) in resistance.drugs"
                          :key="id"
                        >
                          {{ drug.name.en }}
                        </li>
                      </ul>
                    </template>
                    <template v-if="resistance.references && resistance.references.length">
                      <div class="text-overline mt-3">
                        {{ $t('References') }}
                      </div>
                      <reference-links
                        :references="resistance.references"
                      />
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <arkscore-card
                    :name="$t('arkscore.antimicrobial_resistance')"
                    :score="lab_result.score_antimicrobial_resistance"
                    :bar-height="10"
                    blurb="This is a measure of the level of resistance detected. Higher scores indicate that the detected resistance affects more drug classes, thereby limiting the number of effective treatment options. The lower the score, the less resistant the patient will be to antibiotic therapy."
                    flat
                  />
                </v-expansion-panel>
              </v-expansion-panels>

              <v-card class="mt-6 mb-2">
                <v-card-title class="text-overline">
                  <v-icon
                    class="mr-1"
                    small
                  >
                    fal fa-fw fa-allergies
                  </v-icon>
                  {{ $t(lab_result.allergies && lab_result.allergies.length ? 'Allergies Reported' : 'No Allergies Reported') }}
                </v-card-title>
                <v-divider />
                <v-list
                  v-if="lab_result.allergies && lab_result.allergies.length"
                  dense
                >
                  <v-list-item
                    v-for="allergy in lab_result.allergies"
                    :key="allergy.name"
                  >
                    {{ allergy.name }}
                  </v-list-item>
                </v-list>
              </v-card>

              <v-card
                v-if="lab_result.pregnant"
                class="mt-6 mb-2"
              >
                <v-card-title class="text-overline">
                  <v-icon
                    class="mr-1"
                    small
                  >
                    fal fa-fw fa-person-pregnant
                  </v-icon>
                  {{ $t('Pregnancy Reported') }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-footer
      fixed
      color="primary darken-2"
      class="py-3"
      style="z-index:1"
      dark
    >
      <v-row justify="space-between">
        <v-col
          cols="auto"
          class="hidden-sm-and-down"
        >
          <h5>
            <v-img
              src="/img/arkstone-logo-name-white.svg"
              :contain="true"
              :width="120"
              position="left"
            />
          </h5>
        </v-col>
        <v-col
          cols="auto"
          class="align-center"
        >
          <span class="text-body-1">
            <span class="hidden-sm-and-down">
              Have questions? Visit our
            </span>
            <a
              style="color:#fff; text-decoration: none;"
              href="http://support.arkstone.ai/support/solutions"
              target="_blank"
            >
              Knowledge Base
              <v-icon
                key="fal fa-arrow-up-right-from-square fa-fw"
                x-small
                class="mt-n1"
              >
                fal fa-arrow-up-right-from-square fa-fw
              </v-icon>
            </a>
          </span>
        </v-col>
        <v-col
          cols="auto"
        >
          <span class="text-body-1">
            1-833-933-ARK-3
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    name: 'Report',
    metaInfo () {
      return {
        title: 'Lab Result ' + (this.lab_result.external_id ? this.lab_result.external_id : ''),
        titleTemplate: '%s | MedsMatrix',
      }
    },
    components: {
      SourceChip: () => import('@/components/source/SourceChip.vue'),
      ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
      TreatmentTable: () => import('@/components/interpretation/TreatmentTable.vue'),
      DrugInfo: () => import('@/components/drug/DrugInfo.vue'),
      ReferenceLinks: () => import('@/components/reference/ReferenceLinks.vue'),
      // LabResult: () => import('@/components/lab_result/LabResult.vue'),
      // pdf: () => import('vue-pdf'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    data: () => ({
      icon: '/img/arkstone-logo.svg',
      loading: true,
      valid: true,
      lab_result: {},
      translations: {},
      allergies: [],
      resistances: [],
      selected_drug: null,
    }),
    computed: {
      questions () {
        var questions = this.$t('questions')
        // if (this.lab_result.interpretation.instructions.one_choice_explained) {
        //   delete questions.treated_length
        // } else {
        //   delete questions.one_choice_explained
        // }
        return questions
      },
      one_choice () {
        let str = ''
        if (this.lab_result.interpretation.one_choice) {
          str = this.treatmentString(this.lab_result.interpretation.one_choice)
        } else {
          var drugs = ''
          Object.values(this.lab_result.interpretation.drugs).forEach((drug) => {
            drugs += drug.name.en + ' ' + drug.impression.dosage
          })
          if (this.lab_result.interpretation.drugs && this.lab_result.interpretation.drugs.length) {
            drugs += ' x '
          }
          str = drugs + this.lab_result.interpretation.impression_duration
        }
        if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale]) {
          return this.translations[this.$i18n.locale].one_choice
        } else {
          return str
        }
      },
      uuid () {
        return Object.keys(this.$route.query)[0]
      },
      fileName () {
        var filename
        if (this.lab_result.external_id) {
          filename = this.lab_result.external_id + ' - ' + this.lab_result.sample_type + ' - ' + this.lab_result.patient.first_name + ' ' + this.lab_result.patient.last_name + ' - Arkstone.pdf'
        }
        return filename
      },
      instructions () {
        if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale]) {
          return this.translations[this.$i18n.locale].instructions
        } else {
          return this.lab_result.interpretation.instructions
        }
      },
      resistance () {
        var lab_result_resistance = this.lab_result.resistance.filter((value, index, self) => self.findIndex(obj => obj.name === value.name) === index)
        if (this.$i18n.locale != 'en' && this.translations[this.$i18n.locale] && this.translations[this.$i18n.locale].resistance) {
          var resistance = []
          lab_result_resistance.forEach((x) => {
            x.description = this.translations[this.$i18n.locale].resistance[x.name]
            resistance.push(x)
          })
          return resistance
        } else {
          return lab_result_resistance
        }
      },
      detected_organisms () {
        return this.lab_result.organisms.filter(x => x.result.detected).sort((a,b) => a.name > b.name ? 1 : -1)
      },
      detected_pathogens () {
        return this.detected_organisms.filter(x => x.significant)
      },
      patient_age() {
        return this.$moment(this.lab_result.created_at).diff(this.lab_result.patient.dob, 'years')
      },
      pediatric () {
        return this.patient_age < 18
      },
      showIndication () {
        var indication
        if (this.lab_result.interpretation.one_choice) {
          indication = this.lab_result.interpretation.one_choice.indication.indication
        } else {
          indication = this.lab_result.interpretation.impression_duration.split('for possible').pop()
        }
        return this.lab_result.interpretation.alternatives.filter(x => x.indication.indication != indication).length > 0
      },
    },
    watch: {
      translations () {
        this.$forceUpdate()
      },
      '$i18n.locale' () {
        if (this.$i18n.locale != 'en' && !this.translations[this.$i18n.locale]) {
          this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
            source: "en",
            target: this.$i18n.locale,
            format: "text",
            q: [this.one_choice]
              .concat(Object.values(this.lab_result.interpretation.instructions))
              // .concat(this.lab_result.organisms.map(x => x.description))
              .concat(this.lab_result.resistance.map(x => x.description)),
          })
            .then((response) => {
              var translations = response.data.data.translations.map(x => x.translatedText)
              var instruction_keys = Object.keys(this.lab_result.interpretation.instructions)
              // var organism_keys = this.lab_result.organisms.map(x => x.name)
              var resistance_keys = this.lab_result.resistance.map(x => x.name)
              this.$set(this.translations, this.$i18n.locale, {
                one_choice: translations[0],
                instructions: instruction_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + 1] }), {}),
                // organisms: organism_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + instruction_keys.length + 1] }), {}),
                resistance: resistance_keys.reduce((obj, key, index) => ({ ...obj, [key]: translations[index + instruction_keys.length + 1] }), {}),
              })
            })
        }
        if (this.$i18n.locale != 'en' && this.lab_result.organisms.filter(x => x.description[this.$i18n.locale]).length != this.lab_result.organisms.filter(x => x.description).length) {
          var organisms = this.lab_result.organisms.map(x => x.description[this.$i18n.locale] ? null : x.description.en)
          this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
            source: "en",
            target: this.$i18n.locale,
            format: "text",
            q: organisms,
          })
            .then((response) => {
              var translations = response.data.data.translations.map(x => x.translatedText)
              for (var i = 0; i < translations.length; i++) {
                this.$set(this.lab_result.organisms[i].description, this.$i18n.locale, translations[i])
              }
            })
        }
        this.$vuetify.rtl = ['iw'].includes(this.$i18n.locale)
      },
      selected_drug () {
        const promise = this.axios.get('report/drugs/' + this.selected_drug.id)

        return promise.then((response) => {
          this.$set(this.selected_drug, 'standard_dosages', response.data.standard_dosages)
        })
      },
    },
    created () {
      if (this.$route.params.hash) {
        this.fetchLabResult()
      }

      this.axios.get('report/resistance')
        .then((response) => {
          this.resistances = response.data
        })

      this.axios.get('report/allergens?relevant=true')
        .then((response) => {
          this.allergies = response.data.map((x) => {
            let y = {}
            y.name = x.name
            y.group = x
            return y
          })
        })
    },
    mounted () {
      if (this.lab_result && this.$i18n.locale != 'en' && !this.translations[this.$i18n.locale]) {
        this.translateText()
      }
    },
    methods: {
      treatmentString (treatment) {
        let str = ''
        Object.values(treatment.regimens).forEach((r) => {
          str += r.drug.name.en + ' ' + r.dosage + ' x ' + r.duration
          if (r.pivot.connector) {
            str += ` ${r.pivot.connector} `
          }
        })
        return str += ' for possible ' + treatment.indication.indication
      },
      fetchLabResult () {
        this.loading = true
        const promise = this.axios.get('report/' + this.$route.params.hash)

        return promise.then((response) => {
          this.lab_result = response.data
        })
          .catch(error => {
            if (error.response?.data?.message) {
              this.$toast.error(error.response.data.message)
            }
          })
          .finally(() => { this.loading = false })
      },
      translateText () {
        this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
          source: "en",
          target: this.$i18n.locale,
          format: "text",
          q: Object.values(this.lab_result.interpretation.instructions).concat([this.one_choice]),
        })
          .then((response) => {
            var instructions = {}
            var keys = Object.keys(this.lab_result.interpretation.instructions)
            response.data.data.translations.map((instruction, index) => {
              instructions[keys[index]] = instruction.translatedText.replaceAll('/ img / ', '/img/').replaceAll(' .svg', '.svg')
            })
            this.$set(this.translations, this.$i18n.locale, {
              one_choice: response.data.data.translations.pop().translatedText,
              instructions: instructions,
            })
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
.branded {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1em;
}
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
</style>
<style>
.arkscore-pill {
  display: inline-block;
  height: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
</style>
